import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "workplace1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto" id="about">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-24 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="ABOUT US" />

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              <p className="font-semibold">Who we are</p>
              We specialise in high-quality garden buildings in the Holyport, Maidenhead and Windsor area. 
              We can produce cost-effective and bespoke garden buildings, suitable for working from home or as 
              a games room, workshop, home gym, art studio, music room, man cave or anything else you require.
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              <p className="font-semibold">Why get a garden room?</p>
              Having your own dedicated space outside the house allows you to focus on the task at hand. Free from distractions,
              pets and even family members, it unlocks your productivity and concentration capabilities. And when it's time to return 
              to the real world, it's just a short walk away!
            </p>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative" >
            <GatsbyImage
            style={{borderRadius: "50%", marginLeft: "25px"}}
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
