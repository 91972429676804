import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";

const FAQ = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "workplace2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto" id="faq">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-2 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-8 relative" >
            <GatsbyImage
            style={{borderRadius: "50%", marginRight: "25px"}}
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
          </div>
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="FREQUENTLY ASKED QUESTIONS" />

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              <p className="font-semibold">Do I need planning permission?</p>
              The majority of garden buildings can be built under permitted development rules, with no need to apply for planning permission. 
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              <p className="font-semibold">Do garden rooms need to pass building regulations?</p>
              Garden rooms are exempt from building regulations requirements, provided certain size limits and boundary distances are respected. 
              We can advise you in more detail on the site survey.
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              <p className="font-semibold">Will it be warm enough?</p>
              Yes! We add insulation to the floor, walls and ceiling to ensure the room is sufficently hot in winter while remaining cool in summer.
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              <p className="font-semibold">Is my property suitable for a garden room?</p>
              An open, flat space is the ideal basis for a garden room but we can work with tight spaces and sloped ground too. 
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              <p className="font-semibold">How much will it cost?</p>
              Cost can vary considerably depending on the type of foundation, size of the building and spec required. We would be happy to discuss your
              options in more detail on the initial site survey.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FAQ;
