import React from "react";

import Seo from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import About from "../components/about";
import FAQ from "../components/faq";

const IndexPage = () => (
  <Layout>
    <Seo />
    <Hero />
    <About />
    <FAQ />
  </Layout>
);

export default IndexPage;
